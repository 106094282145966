<template>
<div class="m-2">
  <b-alert show>
    Requires roomRTC
  </b-alert>
  <div class="form-inline sub-mr-2 sub-mt-2">
    <div>EventId</div><b-input type="text" v-model="eventId"/>
  </div>
  <div class="form-inline sub-mr-2 sub-mt-2">
    <div>RoomId</div><b-input type="text" v-model="roomId"/>
  </div>
  <div class="form-inline sub-mr-2 sub-mt-2">
    <div>User name prefix</div><b-input type="text" v-model="prefix"/>
  </div>
  <div class="form-inline sub-mr-2 sub-mt-2">
    <div>Num Users:</div>
    <b-input type="text" v-model="numUsers" style="width: 3em;"/>
    <b-form-input class="w-25" v-model="numUsers" type="range" min="1" max="32"/>
  </div>
  <div class="form-inline sub-mr-2 sub-mt-2">
    <button class="btn btn-primary" @click="start()">Start</button>
    <button class="btn btn-primary" @click="deleteUsers()">Clean Up</button>
  </div>
  <template v-if="showVideos">
    <div v-for="(v, i) in videos" :key="'v_'+i">
      <video ref="videos" :src="v" width="400px" autoplay loop muted/>
    </div>
  </template>
  <div v-for="(u, i) in rtcUsers" :key="'r_'+i">
    <roomRTC
      :user="u"
      :room="{id:roomId, focusedStreams:true}"
      :user-config="{noUserMedia:true, broadcastOnly:true}"/>
  </div>
</div>
</template>

<script>
import { getLog } from "@/services/log";
let log = getLog("test-users");
import roomRTC from "@/components/roomRTC";
import { getBrowser, randomString } from '../../services/utils';
import { getStreamDimensions } from '../../services/mediautils';
import { db } from "@/services/db";

export default {
  components: {
    roomRTC
  },
  data() {
    return {
      eventId: "laurent-meeting",
      roomId: "e9e68Pd1l3SqjGXlMtSr",
      prefix: getBrowser(),
      numUsers: 2,
      rtcUsers: [],
      showVideos: false,

      videos: [
        "/abby.mp4",
        "/dolapo.mp4",
      ],
      testStreams: [],
    };
  },
  mounted() {
    log.log("mounted");
  },
  beforeDestroy() {
    this.deleteUsers();
 
  },
  methods: {
    start() {
      this.showVideos = true;
      setTimeout(() => {
        this.createUsers();
      }, 1000);
    },
    createUsers() {
      // Test streams
      this.testStreams = [];
      this.$refs.videos.forEach(async (v, i) => {
        this.testStreams[i] = v.captureStream();
        log.log("testStream", i, await getStreamDimensions(this.testStreams[i]));
      });
      // rtcUsers
      if (this.rtcUsers.length > 0) {
        this.deleteUsers();
      }
      for (let i = 0; i < this.numUsers; ++i) {
        this.rtcUsers.push({
          id: 'test' + randomString(16),
          name: `${this.prefix} ${i}`,
          muted: true,
          stream: this.testStreams[i % this.testStreams.length],
        });
      }
      // eventUsers
      if (this.eventId) {
        let batch = db.batch();
        this.rtcUsers.forEach((u) => {
          batch.set(db.doc(`LiveEvents/${this.eventId}/users/${u.id}`), {name:u.name});
        })
        batch.commit();
      }
    },
    deleteUsers() {
      log.log("deleteUsers");
      let batch = db.batch();
      this.rtcUsers.forEach((u) => {
        batch.delete(db.doc(`LiveEvents/${this.eventId}/users/${u.id}`));
      });
      batch.commit();
      this.rtcUsers = [];      
    }
  }
}
</script>

<style>

</style>